<template>
    <div class="orderList">
        <div v-if="orderList.length > 0">
            <div class="content" v-for="(item, i) in orderList" :key="i">
                <div class="orderInfo">
                    <div class="item">
                        <span>产品名称：</span>
                        <span>{{item.ProductName}}</span>
                    </div>
                    <div class="item">
                        <span>总订购数量：</span>
                        <span>{{item.TotalCount}}套</span>
                    </div>
                    <div class="item">
                        <span>订单编号：</span>
                        <span>{{item.OrderId}}</span>
                    </div>
                    <div class="item">
                        <span>下单时间：</span>
                        <span>{{item.OrderDate}}</span>
                    </div>
                </div>
                <div class="userForm" v-for="(el, index) in item.formArr" :key="index">
                    <van-form  :ref="`form${index}`">
                        <div class="userInfo">
                            <van-field label="姓名:" v-model="el.Ex1" readonly />
                            <van-field label="性别:" v-model="el.Ex3" readonly />
                            <van-field label="年龄:" v-model="el.Age" readonly />
                        </div>
                        <div class="course">
                            <div class="marbot">
                                <van-field label="主疗程：" readonly v-model="el.ProductName"/>
                            </div>
                            <div class="flexSty">
                                <div class="mark"></div>
                                <van-field
                                    type="digit"
                                    readonly
                                    v-model="el.OrderNum"
                                    label="定制套数："
                                />
                                <div>套</div>
                            </div>
                            <div class="flexSty">
                                <div class="mark"></div>
                                <van-field
                                    readonly
                                    v-model="el.ReceAddress"
                                    label="收货地址："
                                />
                            </div>
                            <div class="flexSty">
                                <div class="mark"></div>
                                <van-field
                                    readonly
                                    v-model="el.ReceName"
                                    label="收件人："
                                />
                            </div>
                            <div class="flexSty">
                                <div class="mark"></div>
                                <van-field
                                    type="tel"
                                    readonly
                                    v-model="el.RecePhone"
                                    label="手机号："
                                />
                            </div>
                            <div class="flexSty">
                                <div class="mark"></div>
                                <van-field
                                    readonly
                                    v-model="el.Remark"
                                    rows="1"
                                    autosize
                                    label="其他备注"
                                    type="textarea"
                                />
                            </div>
                        </div>
                    </van-form>
                </div>
            </div>
        </div>
        
        <div class="null" v-else>
            暂无数据
        </div>
    </div>
</template>

<script>
import { queryMallApi } from '@/api/index'
import { sortArr } from '@/util/validate'

export default {
    components: {
        
    },
    data() {
        return {
            orderList: []
        };
    },
    props:{
        
    },
    created() {
        const queryParams = this.$route.query;
        this.getOrderDetil(queryParams);
    },
    mounted() {
    },
    methods: {
        // 获取订单列表
        getOrderDetil(params){
            this.$toast.loading({
                duration: 5000,
                message: '加载中',
            })

            const formData = new FormData();
            formData.append('action', 'GetNewOrderlist');
            formData.append('userId', params.userId);
            queryMallApi(formData).then(res => {
                const { returnObj } = res
                if(returnObj) {
                    const listData = sortArr(returnObj, 'OrderId');
                    let newListData = [];
                    listData.map(item => {
                        if(item.length > 0) {
                            let newObj = {
                                formArr: item
                            };
                            item.forEach(el => {
                                newObj.ProductName = el.ProductName;
                                newObj.OrderId = el.OrderId;
                                newObj.TotalCount = el.TotalCount;
                                newObj.OrderDate = el.OrderDate;
                            })
                            newListData.push(newObj)
                        }
                    })
                    this.orderList = newListData;

                    if(returnObj.length > 0) {
                        this.$toast.success({
                            message: '订单列表获取成功',
                        })
                    } else {
                        this.$toast({
                            message: '列表暂无数据',
                        })
                    }

                } else {
                    this.$toast.fail({
                        message: '订单列表获取失败',
                    })
                }
            })
        },
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.orderList {
    padding-top: 40px;
    height: 100%;
    .content {
        width: 100%;
        border-radius: 20px;
        border-top: 1px solid #fff;
        background-color: #f1f1f1;
        padding: 20px;
        margin-bottom: 40px;
        .orderInfo {
            display: flex;
            flex-wrap: wrap;
            color: #888888;
            font-weight: 700;
            .item {
                width: 50%;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
        }
        .userForm {
            background: #fff;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 4px 5px 7px #C0C0C0;
            margin-top: 20px;
            position: relative;
            .userInfo {
                display: flex;
                color: #888888;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                width: 100%;
                /deep/ .van-cell {
                    width: 70%;
                    padding: 0;
                }
                /deep/ .van-field__label {
                    color: #888888;
                    width: 2.6em;
                    font-size: 12px;
                    margin-right: 0;
                }
                /deep/ .van-button {
                    background-color: #FF9480;
                    border: 1px solid #FF9480;
                }
                
                /deep/ .van-cell::after {
                    display: none;
                }
            }
            .course {
                color: #6D6D6D;
                font-size: 14px;
                /deep/ .van-cell {
                    padding: 0;
                }
                /deep/ .van-field__label {
                    width: 5.6em;
                }
                /deep/ .van-cell__title {
                    margin-right: 0;
                    color: #6D6D6D;
                }
                .mark {
                    width: 10px;
                    height: 10px;
                    border-radius: 1px;
                    background-color: #D3F8FF;
                    margin-right: 10px;
                }
                .marbot {
                    margin-bottom: 5px;
                }
                .flexSty {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    /deep/ .van-cell {
                        width: 70%;
                    }
                }
            }
        }
        .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            .userList {
                width: 80%;
                height: 70%;
                background: #fff;
                border-radius: 20px;
                overflow: hidden;
                .head {
                    height: 25%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #E5F1F7;
                }
                .listInfo {
                    padding: 10px;
                    height: 75%;
                    .title {
                        color: #888888;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        font-weight: 700;
                        .tip {
                            width: 5px;
                            height: 18px;
                            margin-right: 10px;
                            background-color: #FF9480;
                            border-radius: 2px;
                        }
                    }
                    .listItem {
                        height: 200px;
                        height: calc(100% - 108px);
                        overflow-y: scroll;
                        /deep/ .van-cell {
                            font-size: 12px;
                            justify-content: space-between;
                        }
                        .userData {
                            width: 90%;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            color: #6D6D6D;
                            .leb {
                                color: #888888;
                            }
                        }
                    }
                    .userbt {
                        /deep/ .van-button {
                            width: 50%;
                            border-radius: 5px;
                            margin: 20px 0;
                        }
                    }
                    
                }
            }
        }
    }
    .null {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 20px;
    }
}
.hidden {
    overflow: hidden;
}
</style>